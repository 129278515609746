define("discourse/plugins/z-communiteq-features/discourse/components/communiteq-admin-notice", ["exports", "@glimmer/component", "@ember/service", "@ember/template", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _template2, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CommuniteqAdminNotice extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get shouldShow() {
      return this.currentUser && this.currentUser.staff && this.siteSettings.communiteq_admin_notice != "";
    }
    get adminNotice() {
      return (0, _template2.htmlSafe)(this.siteSettings.communiteq_admin_notice);
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldShow}}
          <div class="communiteq-admin-notice" style="background-color: #fefefe; border: 4px solid #a00; padding: 10px;">
            {{this.adminNotice}}
            <br/>
            <br/>
            <div style="text-align: right; display: block;">
              <i>This message is being shown to forum staff only, regular users will not see it.</i>
            </div>
          </div>
        {{/if}}
      
    */
    {
      "id": "fdgx8vSR",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldShow\"]],[[[1,\"      \"],[10,0],[14,0,\"communiteq-admin-notice\"],[14,5,\"background-color: #fefefe; border: 4px solid #a00; padding: 10px;\"],[12],[1,\"\\n        \"],[1,[30,0,[\"adminNotice\"]]],[1,\"\\n        \"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[10,\"br\"],[12],[13],[1,\"\\n        \"],[10,0],[14,5,\"text-align: right; display: block;\"],[12],[1,\"\\n          \"],[10,\"i\"],[12],[1,\"This message is being shown to forum staff only, regular users will not see it.\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/z-communiteq-features/discourse/components/communiteq-admin-notice.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CommuniteqAdminNotice;
  ;
});